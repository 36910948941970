import React from 'react';
import { RawHtml, Accordion, AccordionItem, ModuleContainer, ModuleHeader } from 'ui';
import { weakKey } from '~/shared/utils/jsx';
import { CmsModel } from 'api-types';
import styles from './M120Accordion.module.css';

type M120Props = CmsModel.M120AccordionModule;

export const M120Accordion = ({ headline, content, ...rest }: M120Props) => {
    return (
        <ModuleContainer hasGutter {...rest}>
            <div className={styles.headerAlignment}>
                <ModuleHeader headline={headline} variant='display3' />
                <Accordion>
                    {content.map((item) => (
                        <AccordionItem header={item.headline} key={weakKey(item)}>
                            <RawHtml html={item.description} />
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </ModuleContainer>
    );
};
